import { DDQuestion, DocumentDrafterQuestionnaire, QuestionnaireWrapper, RequestForOfferQuestionnaireValidator } from '@dims/components';
import { DateTime } from 'luxon';
import deliveryAgreementIds from '@/models/DeliveryAgreementIds';
import { SolutionType, Tender1713 } from '@/models';

export default class RequestQuestionnaireValidator implements RequestForOfferQuestionnaireValidator {
  questionnaire;
  constructor(readonly tender: Tender1713, questionnaire: DocumentDrafterQuestionnaire) {
    this.questionnaire = new QuestionnaireWrapper(
      this.tender.agreementConfiguration.questionnaires.requestForOffer,
      questionnaire,
    );
  }
  get incomplete() {
    return !this.questionnaire.content.complete;
  }

  get numericValidators() {
    return {
      ['default']: { min: 0, max: undefined },
    };
  }

  minDate(question: DDQuestion): string | undefined {
    if (question.questionId === deliveryAgreementIds.idDeliveryDate) {
      return this.tender.deadlineForTender ?? undefined;
    }
    if (question.dataFieldType === 'Date') {
      return DateTime.local().toISODate();
    }
    return undefined;
  }

  get isSubcriteriaValid() {
    return this.sumOfSubCriteria === 100;
  }

  get sumOfSubCriteria() {
    // Don't check sub criteria on category 1
    if (this.tender.data.solutionType === SolutionType.Category1) return 100;
    const sum = (arr: number[]) => arr.reduce((previousq, q) => previousq + q, 0);
    const weightQuestionIds = [
      deliveryAgreementIds.idCompetenceWeight,
      deliveryAgreementIds.idSolutionWeight,
    ];
    const values = this.questionnaire.content.questions
      .filter((q) => weightQuestionIds.includes(q.questionId))
      .map((q) => (q.answers?.[0] && q.answers[0].value !== '' ? parseFloat(q.answers[0].value) : 0));
    return sum(values);
  }

  get subCriteriaValidation() {
    if (this.isSubcriteriaValid) {
      return null;
    }
    const sum = this.sumOfSubCriteria;
    return { message: `Summen af delkriterierne skal være 100% (Summen er ${sum})` };
  }
}
