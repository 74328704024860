<template>
  <v-card class="pa-3" :color="cardStyle">
    <v-card-text>
      <h2 class="mt-0 mb-2 text-uppercase">Opsummering</h2>
      <p>
        Samlet beregning af kontraktens forventede værdi og evalueringstekniske
        pris, på baggrund af de afgivne priser.
      </p>
      <template v-if="questions.length">
        <TcoCalculationSummaryTable
          :questions
          :tableStyle
        />
        <div>
          <div class="d-flex align-end flex-column mt-5">
            <template v-if="tcoCalculationResult && tcoCalculationResult.tcoPrice">
              <div class="d-flex">
                <div>Evalueringsteknisk pris</div>
              </div>
              <h3
                v-if="tcoCalculationResult && tcoCalculationResult.tcoPrice"
                class="text-success font-weight-bold mb-5 mt-0"
              >
                {{ $format.currency(tcoCalculationResult.tcoPrice) }}
              </h3>
            </template>
            <v-dialog v-model="tcoCalculationDialog" width="1600">
              <template #activator="{ props: dialogProps }">
                <v-tooltip location="bottom" :disabled="tcoCalculationResult !== null">
                  <template #activator="{ props: tooltipProps }">
                    <div v-bind="tooltipProps" class="d-inline-block">
                      <v-btn v-bind="dialogProps" class="secondary-button-mini" :disabled="!tcoCalculationResult">Vis udregning</v-btn>
                    </div>
                  </template>
                  <span>Tilgængelig når ét prispunkt er udfyldt</span>
                </v-tooltip>
              </template>
              <TcoCalculationDialog
                v-if="tcoCalculationResult && tcoCalculationResult.tcoCalculationTableMap"
                :questions
                :tables="tcoCalculationResult.tcoCalculationTableMap"
                @closeDialog="tcoCalculationDialog = false"
              />
            </v-dialog>
          </div>
        </div>
      </template>
      <v-progress-circular
        v-else
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { Question, TcoCalculationResult } from '@dims/components';
import { ref } from 'vue';
import TcoCalculationDialog from './TcoCalculationDialog.vue';
import TcoCalculationSummaryTable from './TcoCalculationSummaryTable.vue';

const { questions, cardStyle, tableStyle, tcoCalculationResult } = defineProps<{
  questions: Question[],
  cardStyle?: string,
  tableStyle?: string,
  tcoCalculationResult: TcoCalculationResult | undefined }>();

const tcoCalculationDialog = ref(false);

</script>
