import { Calculator, Question, Offer, QuestionTypesEnum, TenderStateEnum, Tender, ValueQuestionBase, toCurrencyFormatDK2, OfferEvaluation } from '@dims/components';
import { Offer1713 } from '@/models/Offer';
import { DraftTender1713, SolutionType, Tender1713 } from '@/models/Tender';

export const getTotalPricePerPoint = (offer: Offer1713, _tender: Tender1713): number => offer.data.bpqScore ?? 0;

export const tenderStub: DraftTender1713 = {
  data: {
    solutionType: SolutionType.Category1,
    requestForOfferDone: false,
    specificationDone: false,
    deliveryAgreementDone: false,
  },
  agreementName: 'dummy',
  awardCriteriaType: 'BPQRatio',
  state: TenderStateEnum.Prepare,
};

/* Expose the agreement specific calculations in a generic way to be used by shared components */
export class Calculator1713 implements Calculator {
  totalCost(offer: Offer, _tender: Tender): number {
    return offer.contractSum ?? 0;
  }

  sortByCustomRanking(_offers: Offer[]): Offer[] {
    throw new Error('Custom ranking not supported - Method not implemented.');
  }

  calculatePrice(offer: Offer, _tender: Tender1713): number {
    return offer.contractSum ?? 0;
  }

  sortByPrice(_offers: Offer[], _tender: Tender): Offer[] {
    throw new Error('Price evaluation not supported - Method not implemented.');
  }

  sortBPQBestFirst(offers: Offer[], tender: Tender1713, _offersEvaluations: OfferEvaluation[]) {
    return offers.sort(
      // should be negative if a is the best offer, since we want best first
      // since the lowest price is best, result is price(a) - price(b)
      (a, b) => getTotalPricePerPoint(a, tender)
        - getTotalPricePerPoint(b, tender),
    );
  }

  /// Check if pointscore is below minimum. Agreement specific rule.
  isScoreBelowConditionalThreshold_BackendCalculation(offer: Offer1713) {
    // Only if points have been entered (i.e. PointScore > 0) is the flag considered
    return ((offer.data.bpqPointScore ?? 0) > 0)
      ? (offer.data.bpqPointScoreTooLow ?? true)
      : false;
  }

  /// Offers with points below a certain threshold are marked as unconditional. Remove later as described in #13384
  isScoreBelowConditionalThreshold_FrontendCalculation(_offer: Offer, _tender: Tender) {
    // For 17.13 this will always return false, since this is only calculated backend
    return false;
  }

  contractSum(offer: Offer, _tender: Tender) {
    return offer.contractSum ?? 0;
  }

  /** Shown in 'Samlet score' column for BPQ evaluation
   * should represent the factor used for ordering the offers */
  bpqScoreText(offer: Offer1713, tender: Tender1713, _conditionalOffers: Offer[], _offerEvaluation?: OfferEvaluation) {
    const pricePrPoint = getTotalPricePerPoint(offer, tender);
    if (pricePrPoint > 0) return `${toCurrencyFormatDK2(pricePrPoint)} / point`;
    return 'Kvalitets-score ikke indtastet';
  }
}

export function getCalculator() {
  return new Calculator1713();
}

export function incompleteQuestion(question: Question) {
  const secs = question.questions?.filter((q) => q.questionType === QuestionTypesEnum.SECTION);

  if (secs) {
    const result = secs
      .flatMap((s) => s.questions)
      .filter((q) => q?.questionType === QuestionTypesEnum.BOOLEAN)
      .map((b) => (b as ValueQuestionBase).value)
      .every((v) => v === 'false');
    return result;
  }
  return false;
}
